import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import "../styles/style.scss";

import Layout from "../components/Layout";
import YearDisplay from "../components/YearDisplay";
import Seo from "../components/Seo";

// markup
const OtherMedia = ({ data }) => {
  const [years, setYears] = useState([]);
  const pageContent = data.otherMediaPageContent.nodes[0];

  useEffect(() => {
    data.otherMediaItems.nodes.map((item) => {
      let newYear = item.frontmatter.publicationdate;
      if (!years.includes(newYear)) {
        let shallowCopy = [...years, newYear];
        setYears(shallowCopy);
      }
      return years;
    });
  });

  return (
    <Layout page={pageContent}>
      <Seo
        title={pageContent.frontmatter.title}
        description={pageContent.frontmatter.description}
      />
      <div className="container d-flex justify-content-center">
        <div className="content-wrapper my-5">
          <YearDisplay years={years} items={data.otherMediaItems.nodes} />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query otherMediaPageQuery {
    otherMediaPageContent: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/othermedia/" }
        frontmatter: { type: { eq: "page_content" } }
      }
    ) {
      nodes {
        frontmatter {
          title
          description
          header_image {
            childImageSharp {
              gatsbyImageData(width: 1200, placeholder: BLURRED)
            }
          }
        }
        html
      }
    }
    otherMediaItems: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "othermedia" } } }
      sort: { fields: frontmatter___publicationdate, order: DESC }
    ) {
      nodes {
        frontmatter {
          title
          publicationdate(formatString: "YYYY")
        }
        html
      }
    }
  }
`;

export default OtherMedia;
