import React from "react";

const YearDisplay = ({ years, items }) => (
  <>
    <div className="container">
      {years
        .sort((a, b) => (a < b ? 1 : -1))
        .map((year) => (
          <div className="row" key={year}>
            <div className="col-lg-4">
              <h2>{year}</h2>
            </div>
            <div className="col-lg-8 pt-2">
              <ul className="table-list">
                {items
                  .filter((item) => item.frontmatter.publicationdate === year)
                  .map((item) => (
                    <li
                      dangerouslySetInnerHTML={{ __html: item.html }}
                      key={item.frontmatter.title}
                    ></li>
                  ))}
              </ul>
            </div>
          </div>
        ))}
    </div>
  </>
);

export default YearDisplay;
